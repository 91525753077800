import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LiveScoresPipe } from './live-scores.pipe';
import { DurationPipe } from './duration.pipe';
import { SortPipe } from './sort.pipe';
import { CustomCurrencyPipe } from './custom-currency.pipe';
import { SafePipe } from './safe.pipe';
import { ArrangeGamePipe } from './arrange-game.pipe';
import { UserSlugPipe } from './user-slug.pipe';
import { BaseUrlPipe } from './base-url.pipe';
import { SportUrlPipe } from './sport-url.pipe';
import { ReplacePipe } from './replace.pipe';
import { DurationServerPipe } from './duration-server.pipe';

@NgModule({
  declarations: [
    LiveScoresPipe,
    DurationPipe,
    SortPipe,
    CustomCurrencyPipe,
    SafePipe,
    ArrangeGamePipe,
    UserSlugPipe,
    BaseUrlPipe,
    SportUrlPipe,
    ReplacePipe,
    DurationServerPipe
  ],
  imports: [CommonModule],
  exports: [
    LiveScoresPipe,
    DurationPipe,
    SortPipe,
    CustomCurrencyPipe,
    SafePipe,
    ArrangeGamePipe,
    UserSlugPipe,
    BaseUrlPipe,
    SportUrlPipe,
    ReplacePipe,
    DurationServerPipe
  ],
})
export class PipesModule {}
