import { Injectable } from '@angular/core';
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { SportService } from '../services';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root',
})
export class SportResolver implements Resolve<boolean> {
  constructor(
    private _common: CommonService,
    private _sport: SportService,
  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      this._common
        .getConfiguration()
        .then((res) => {
          this._common.configuration.next(res.data);
          res.data.sports.forEach((sport: any) => {
            this._common.sports.push(sport.value.toLowerCase());
            if (
              sport.value.toLowerCase() === res.data.default_sport.toLowerCase()
            ) {
              // this._sport.selectedSport.next(sport.value);
              if (sport.value !== localStorage.getItem('default_sport')) {
                localStorage.setItem('default_sport', sport.value);
              }
            }
          });
          resolve(true);
        })
        .catch((err) => {
          reject(true);
        });
    });
  }
}
