import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
// import { AuthService } from 'src/app/core';

const TOKEN = 'Authorization';
@Injectable({
  providedIn: 'root'
})
export class HttpTokenInterceptor implements HttpInterceptor {
  userToken: string|null = '';
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(request.url.search(environment.apiUrl) >= 0){
      this.userToken = localStorage.getItem('xsrf');
      if (!request.headers.has(TOKEN) && this.userToken) {
        request = request.clone({
          setHeaders: {
            'Authorization': `${'Bearer '}${this.userToken}`
          }
        });
      }
      request = request.clone({
        headers: request.headers.set('Accept', 'application/json')
      });
      request = request.clone({
        headers: request.headers.set('xsrf', '1136899373619364619174637293644')
      });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }));
  }


}
