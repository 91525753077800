import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IUser, UserService } from './core';
import { CommonService } from './core/services/common.service';
import { OneSignal } from 'onesignal-ngx';
import { Subscription } from 'rxjs';
// import { GoogleAnalyticsService } from 'ngx-google-analytics';
// declare let Adjust: any;
declare let dataLayer: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  header = true;
  subscriptions = new Subscription();
  constructor(
    private readonly router: Router,
    // private _ga: GoogleAnalyticsService,
    private _user: UserService,
    private _common: CommonService,
    private _activated: ActivatedRoute,
    private oneSignal: OneSignal // private _ga:GoogleAnalyticsService
  ) {
    //jD_TT-xNngBG25zQKVzb
    // if(this._common.isBrowser){
    //   dataLayer.push({'event': 'optimize.activate'});
    // }
    const queryparamsSubscription = this._activated.queryParamMap.subscribe(
      (data) => {
        this.header = data.get('header') == 'false' ? false : true;
      }
    );
    if (this._common.isBrowser && location.host.search('localhost') == -1) {
      this.oneSignal.init({
        appId: 'df002a8f-0191-4aad-be79-b22b353de298',
      });
    }
    // this.checkAuthUser();
    const authUserSubscription = this._user.authUser.subscribe((user) => {
      if (user?.id) {
        this.oneSignal.setExternalUserId(user?.id?.toString());
        this.oneSignal.setEmail(user?.email);
        this._common.checkSubscription(user.id).then((data: any) => {
          if (data.subscriber?.subscriptions) {
            let subscriptions = data.subscriber?.subscriptions;
            let subscribed = false;
            for (const key in subscriptions) {
              if (Object.prototype.hasOwnProperty.call(subscriptions, key)) {
                const element = subscriptions[key];
                if (new Date(element.expires_date) > new Date() && !element.billing_issues_detected_at) {
                  this._user.subscribed.next(true);
                  this._common.getStripeSubscription().then((res) => {
                    this._user.stripe_id.next(res.subscription_id || '');
                  });
                  subscribed = true;
                }
              }
            }
            if (!subscribed) {
              // this.openPopup();
            }
          }
        });
      } else {
        this._user.subscribed.next(false);
      }
    });
    // if (!this._common.JwTUserId) {
    //   this.openPopup();
    // }
    // this._common.getStateCountry();
    // this.ngxSmartBannerService.initialize({
    //   viewContainerRef: this.viewContainerRef,
    //   daysHidden: 15,
    //   daysReminder: 90,
    //   title: 'Kingpin Pro',
    //   author: 'Kingpin',
    //   price: 'Free',
    //   rating: {
    //     ios: 4.7,
    //     android: 4.2,
    //   },
    //   icon: {
    //     ios: 'https://kingpin.pro/assets/img/logo.png',
    //     android: 'https://kingpin.pro/assets/img/logo.png',
    //   },
    //   buttonUrl: {
    //     ios: APPSTORE,
    //     android: PLAYSTORE,
    //   },
    // });

    const eventsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this._common.isBrowser) {
          window.scrollTo(0, 0);
        }
        this._common.checkRouterData(this._activated.snapshot.data);
        // this._ga.pageView(event.url);
      }
    });
    this.subscriptions.add(queryparamsSubscription);
    this.subscriptions.add(authUserSubscription);
    this.subscriptions.add(eventsSubscription);
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (this._common.isBrowser && this._common.isMobile) {
      setTimeout(() => {
        window.scrollBy(0, 10);
      }, 20);
    }
  }

  async checkAuthUser() {
    if (localStorage.getItem('xsrf')) {
      if (!this._user.authState.getValue()) {
        this._user.authState.next(true);
        this._user.authuser().then((res: any) => {
          if (res.status && res.user) {
            this._user.authUser.next(res.user);
          } else {
            localStorage.removeItem('xsrf');
            this._user.authState.next(false);
            this._user.authUser.next({} as IUser);
          }
        });
      }
    }
  }

  openPopup() {
    if (!this._common.isBrowser || !this.header) return;
    if (!sessionStorage.getItem('modal')) {
      setTimeout(() => {
        if (this.header) {
          sessionStorage.setItem('modal', 'true');
          document.getElementById('pricing-button')?.click();
        }
      }, 1500);
    }
    // this.loadScript();
  }
}
