import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, AuthRegister, IUser } from '@core/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClientInterceptor } from '../interceptor';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  authUser = new BehaviorSubject<any>(null);
  authState = new BehaviorSubject<boolean>(false);
  subscribed = new BehaviorSubject<boolean>(false);
  stripe_id = new BehaviorSubject<string>('');
  userstate = new BehaviorSubject<any>(null);

  /**
   * @param {HttpClientInterceptor} _http For Http Calls
   */
  constructor(private _http: HttpClientInterceptor, private _router: Router) {}

  /**
   * @function login for login user
   * @param {Auth} user user email & password to validare user
   */
  login(user: Auth): Promise<{
    status: boolean;
    token_type: string;
    access_token: string;
    expires_at: string;
    message?: string;
    unverified?: boolean;
  }> {
    return this._http.post(`/signin`, user).toPromise();
  }

  /**
   * @function login for login user
   * @param {Auth} user user email & password to validare user
   */
  loginRegister(user: Auth): Promise<{
    status: boolean;
    user_id: number;
    token_type: string;
    access_token: string;
    expires_at: string;
    message?: string;
  }> {
    return this._http.post(`/login-register`, user).toPromise();
  }

  /**
   * @function login for login user
   * @param {Auth} user user email & password to validare user
   */
  socialLogin(dataobj: Object): Promise<{
    status: boolean;
    token_type: string;
    access_token: string;
    expires_at: string;
    message?: string;
  }> {
    return this._http.post(`/social-login`, dataobj).toPromise();
  }

  /**
   * @function register for register user
   * @param {Auth} user user email & password to validare user
   */
  signup(user: AuthRegister): Promise<{
    status: boolean;
    token_type: string;
    access_token: string;
    expires_at: string;
    message?: string;
    verification_required:any;
  }> {
    return this._http.post(`/register`, user).toPromise();
  }

  /**
   * @function register for register user
   * @param {Auth} user user email & password to validare user
   */
  userDetails(
    id: number
  ): Promise<{ status: boolean; message: string; data: object }> {
    return this._http.get(`/userdetail/${id}`).toPromise();
  }

  /**
   * @function register for register user
   * @param {Auth} user user email & password to validare user
   */
  authuser(): Promise<{ status: boolean; message: string; user: object }> {
    return this._http.get(`/auth-user`).toPromise();
  }

  /**
   * @function topusers for register user
   * @param {HttpParams} user user email & password to validare user
   */
  topUsers(
    params: HttpParams = new HttpParams()
  ): Promise<{ status: boolean; message: string; data: any[] }> {
    return this._http.get(`/top-kingpins`, params).toPromise();
  }

  /**
   * @function topusers for register user
   * @param {HttpParams} user user email & password to validare user
   */
  forgotPassword(params: {
    email: string;
  }): Promise<{ status: boolean; message: string; data: any[] }> {
    return this._http
      .post(`/forgot_password_authenticated`, params)
      .toPromise();
  }

  /**
   * @function topusers for register user
   * @param {HttpParams} user user email & password to validare user
   */
  resetPassword(params: {
    email: string;
    verification_code: string;
    password: string;
  }): Promise<{ status: boolean; message: string; data: any[] }> {
    return this._http.post(`/reset_password_authenticated`, params).toPromise();
  }

  /**
   * @function topusers for register user
   * @param {HttpParams} user user email & password to validare user
   */
  verifytoken(
    otp: any
  ): Promise<{ status: boolean; message: string; access_token: any }> {
    return this._http.get(`/verify-code/${otp}`).toPromise();
  }

  /**
   * @function topusers for register user
   * @param {HttpParams} user user email & password to validare user
   */
  pickHistory(
    params = new HttpParams()
  ): Promise<{ status: boolean; message: string; data: any[]; user: string }> {
    return this._http.get(`/get-pick-history`, params).toPromise();
  }

  /**
   * @function topusers for register user
   * @param {HttpParams} user user email & password to validare user
   */
  upcomingPicks(
    params = new HttpParams()
  ): Promise<{ status: boolean; message: string; data: any[]; user: string }> {
    return this._http.get(`/get-upcoming-picks`, params).toPromise();
  }

  /**
   * @function topusers for register user
   * @param {HttpParams} user user email & password to validare user
   */
  userStats(
    id: number,
    params = new HttpParams()
  ): Promise<{ status: boolean; message: string; data: any }> {
    return this._http.get(`/user-stats/${id}`, params).toPromise();
  }

  /**
   * @function topusers for register user
   * @param {HttpParams} user user email & password to validare user
   */
  paymentHistory(
    id: number,
    params = new HttpParams()
  ): Promise<{ status: boolean; message: string; data: any[] }> {
    return this._http.get(`/payment-history/${id}`, params).toPromise();
  }

  /**
   * @function topusers for register user
   * @param {HttpParams} user user email & password to validare user
   */
  follow(obj: {
    user_id: any;
    kingpin_id: any;
  }): Promise<{ status: boolean; message: string; data: any[] }> {
    return this._http.post(`/only_follow_authenticated`, obj).toPromise();
  }

  /**
   * @function topusers for register user
   * @param {HttpParams} user user email & password to validare user
   */
  unfollow(obj: {
    user_id: any;
    kingpin_id: any;
  }): Promise<{ status: boolean; message: string; data: any[] }> {
    return this._http.post(`/unfollow_authenticated`, obj).toPromise();
  }

  /**
   * @function topusers for register user
   * @param {HttpParams} user user email & password to validare user
   */
  updateProfile(
    data: any
  ): Promise<{ status: boolean; message: string; data: any[] }> {
    return this._http.post(`/update_profile_authenticated`, data).toPromise();
  }

  isLoggedIn() {
    if (!this.authState.getValue()) {
      this._router.navigate(['/auth/login'], {
        queryParams: { returnUrl: this._router.url },
      });
      return false;
    } else {
      return true;
    }
  }

  getNotifications(): Promise<{ status: boolean; message: string; data: any }> {
    return this._http.get(`/get-notifications/notifications`).toPromise();
  }

  getRealAccountBalance(): Promise<{ status: boolean; balance: number }> {
    return this._http.get(`/real-account-balance`).toPromise();
  }

  updateNotification(
    userid: any,
    obj: any
  ): Promise<{ status: boolean; msg: string }> {
    return this._http.put(`/update-notification/${userid}`, obj).toPromise();
  }
}
