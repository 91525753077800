import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoAuthGuard } from './core/guard/no-auth.guard';
import { SportResolver } from './core/resolvers/sport.resolver';
// import { SportResolver } from './core/resolvers/sport.resolver';

const routes: Routes = [
  {
    path: 'auth',
    canActivateChild: [NoAuthGuard],
    loadChildren: () =>
      import('./views/pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    resolve: { sports: SportResolver },
    loadChildren: () =>
      import('./views/layout/layout.module').then((m) => m.LayoutModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
