import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClientInterceptor } from '../interceptor';

@Injectable({
  providedIn: 'root',
})
export class SportService {
  includeAllPages = [
    'top-bettors',
    'my-experts',
    'profile',
    'statistics',
    'pick-history',
    'upcoming-picks',
    'articles',
    'videos',
  ];
  excludeSports = [
    'faq',
    'how-it-works',
    'subscription',
    'privacy-policy',
    'terms-of-service',
    'contact-us',
    'request-payout',
    'payment-history',
    'career',
  ];
  // showsports = new BehaviorSubject<Boolean>(true);
  selectedSport = new BehaviorSubject<string>('');
  pickslip = new BehaviorSubject<any[]>([]);
  market_ids: any[] = [];
  /**
   * @param {HttpClientInterceptor} _http For Http Calls
   */
  constructor(private _http: HttpClientInterceptor) {}

  upcomingGeneral(
    sport: string,
    date?: string
  ): Promise<{ upcoming: any[]; live: any[] }> {
    let params = new HttpParams().set('sport', sport);
    if (date) {
      params = params.set('date', date);
    }
    return this._http.get(`/upcoming-live-games`, params).toPromise();
  }

  upcomingGeneralNew(
    sport: string,
    date?: string
  ): Promise<{ upcoming: any[]; live: any[] }> {
    let params = new HttpParams().set('sport', sport);
    if (date) {
      params = params.set('date', date);
    }
    return this._http.get(`/upcoming-live-games`, params).toPromise();
  }

  upcomingGeneralDetail(params: {
    sport: string;
    team?: string;
    slug?: string;
    date?: string;
    match_date?: string;
    limit: number;
    skip: number;
  }): Promise<{
    status: boolean;
    message: string;
    data: any;
    top_users: any;
  }> {
    if (params.date && formatDate(new Date(),'YYYY-MM-dd','en') <= params.date) {
      delete params.date;
    }
    return this._http.post(`/upcoming-general-bet-details`, params).toPromise();
  }

  resetSport() {
    this.selectedSport.next(localStorage.getItem('default_sport') || 'MLB');
  }

  get defaultSport() {
    return localStorage.getItem('default_sport') || '';
  }

  getGamesListBySport(sport: string) {
    let params = new HttpParams().set('sport', sport);
    return this._http.get('/get-bet-list', params).toPromise();
  }

  placePicks(obj: any) {
    return this._http.post('/save_bets_authenticated', obj).toPromise();
  }
}
