import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClientInterceptor } from '@app/core';
import { environment } from '@environments/environment';
import { io, Socket } from 'socket.io-client';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  /**
   * @param {HttpClientInterceptor} _http For Http Calls
   */
  public _socket: Socket | any = {};
  constructor(
    private _http: HttpClientInterceptor,
    private _common: CommonService
  ) {
    if (this._common.isBrowser) {
      this._socket = io(environment.socketUrl);
    }
  }

  /**
   * get latest threads
   * @param obj
   * @returns
   */
  getLatestThreads(obj: {
    sport: string;
    sort: string;
  }): Promise<{ status: boolean; message: string; data: any[] }> {
    return this._http.post(`/get-sport-chat`, obj).toPromise();
  }

  /**
   * Send message
   * @param obj
   * @returns
   */
  sendMessage(
    obj: any
  ): Promise<{ status: boolean; message: string; data: any[] }> {
    return this._http.post(`/send-message`, obj).toPromise();
  }

  /**
   * get replies by message id
   * @param obj
   * @returns
   */
  getReplies(
    id: number
  ): Promise<{ status: boolean; message: string; data: object }> {
    return this._http.get(`/get-message-replies/${id}`).toPromise();
  }

  /**
   * get replies by message id
   * @param obj
   * @returns
   */
  getTopChatters(): Promise<{
    status: boolean;
    message: string;
    data: object;
  }> {
    return this._http.get(`/top-chatters`).toPromise();
  }

  /**
   * get replies by message id
   * @param obj
   * @returns
   */
  getTeamChat(
    params: object
  ): Promise<{ status: boolean; message: string; data: any[] }> {
    return this._http.post(`/get-chat-data`, params).toPromise();
  }

  /**
   * get replies by message id
   * @param obj
   * @returns
   */
  getUserMessages(
    id: number,
    params: HttpParams = new HttpParams()
  ): Promise<{ status: boolean; message: string; data: any[] }> {
    return this._http.get(`/get-user-messages/${id}`, params).toPromise();
  }
}
